import Script from 'next/script';

const hjSettings = {
    hjid: 1666135,
    hjsv: 6,
};

const hotjarSetup = `window.hj=window.hj||function(){window.hj.q=(window.hj.q||[]).push(arguments);}
window._hjSettings=${JSON.stringify(hjSettings)};`;

export const hotjar = (enabled: boolean) =>
    enabled ? (
        <>
            <script id="hotjar-cfg" dangerouslySetInnerHTML={{ __html: hotjarSetup }} />
            <Script id="hotjar-js" src={`https://static.hotjar.com/c/hotjar-${hjSettings.hjid}.js?sv=${hjSettings.hjsv}`} crossOrigin="" />
        </>
    ) : null;

export const Hotjar = ({ enabled }: { enabled: boolean }) => <>{hotjar(enabled)}</>;
