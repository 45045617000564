import { type PropsWithChildren, useEffect } from 'react';

import { t } from 'shared/locale/texts';
import { trackBasicPageView } from 'shared/search/common/tracking';
import { googleBreadcrumb } from './util/googleBreadcrumb';
import { googleFaq } from './util/googleFaq';
import { Hotjar } from 'shared/util/hotjar';
import { verticalConfig } from '@/config/verticalConfig';

import { Head } from 'shared/page/Head';
import { Main } from 'shared/page/Main';
import { FinnLogo } from 'shared/icons/FinnLogo';
import { FrontpageSeo } from 'shared/seo/FrontpageSeo';
import { OtherVerticals } from '@/components/OtherVerticals';
import { CarRentalSale } from '@/components/CarRentalSale';
import { FrontpageBanner } from './FrontpageBanner/FrontpageBanner';
import type { SspProps } from '@/util/verticalSsp';

export const FrontPage = ({
    children,
    loadedVertical,
    cmsPodletContent,
    unleash,
    hasLegalBasis,
    widgetId,
}: PropsWithChildren<SspProps>) => {
    const config = verticalConfig[loadedVertical] ?? verticalConfig.flight;

    useEffect(() => {
        trackBasicPageView(config.vertical);
    }, []);

    if (widgetId) {
        return (
            <div className={`widget widget--${widgetId}`}>
                <div className="widget-container relative mx-auto" data-testid="widgetContainer">
                    {children}
                    {widgetId !== 'finn' ? (
                        <div className="absolute right-16 bottom-16">
                            <span className="align-middle text-gray-500 text-14 mr-12">{t('widget.hosted')}</span>
                            <FinnLogo size="large" className="align-middle inline" />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    const hotjarEnabled = !!unleash?.hotjar && !!hasLegalBasis;

    return (
        <>
            <Head
                title={t(`htmlMetadata.${config.vertical}.title`)}
                description={t(`htmlMetadata.${config.vertical}.description`)}
                canonical={config.canonical}>
                {googleBreadcrumb(config.vertical)}
                {googleFaq(config.vertical)}
            </Head>
            <Main broadcastUrl={`https://www.finn.no/broadcasts?path=https://www.finn.no/reise/fp/${config.vertical}`} className="pb-32">
                {children}
                <FrontpageBanner />
                <OtherVerticals />
                {unleash?.carRentalSale ? <CarRentalSale /> : null}
                {cmsPodletContent ? (
                    <section data-testid="cms" className="my-32" dangerouslySetInnerHTML={{ __html: cmsPodletContent }} />
                ) : null}
                <FrontpageSeo vertical={config.vertical} />
            </Main>
            <Hotjar enabled={hotjarEnabled} />
        </>
    );
};
